.testimonial__container {
    width: 1000px;
    margin-top: 2rem;
}

.testimonial__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom: var(--mb-3);
}

.testimonial__img {
    width: 60px;
    border-radius: 3rem;
    margin-bottom: var(--mb-1);
}

.testimonial__name {
    font-weight: var(--font-medium);
    margin-bottom: 1rem;
}

.testimonial__title {
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-1);
    color: darkslategrey
}

.testimonial__title2 {
    font-weight: var(--font-medium);
    margin-top: 1rem;
    margin-bottom: var(--mb-0-1);
    color: darkslategrey
}

.testimonial__relationship {
    font-weight: var(--font-light);
    color: rgb(12, 5, 63);
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.testimonial__description {
    font-size: var(--small-font-size);
}

/* Swiper Class */
.swiper-pagination-bullet {
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--title-color) !important;
}

.navigation__buttons {
    /* center align to the page */
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.leftBtn {
    font-size: 2rem;
    margin-right: 0.5rem;
}

.rightBtn {
    font-size: 2rem;
    margin-left: 0.5rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .testimonial__container {
        width: initial;
    }

    .testimonial__card {
        padding: 1.25rem 1.5rem;
    }
}